<template>
  <MyLink name="home">
    <img
      src="/img/logo-short-colored.svg"
      alt="main page link"
      class="logo__img"
    />
  </MyLink>
</template>

<script setup></script>

<style lang="scss" scoped>
.logo {
  &__img {
    @include fixedHW(48px);
  }
}
</style>
